<template>
  <transition name="modal-showcase">
    <div @click="$emit('close')" class="modal-showcase-mask">
      <div class="modal-showcase-container" style="overflow-y: scroll;"  @click.stop>
        <div class="showcase-header">
          <div class="header-wrapper" style="justify-content: space-between;">
            <div style="display: flex; align-items: center;height:67px;">
              <router-link :to="'/'+receivedUser.username">
                <img class="avatar" :src="receivedUser.avatar_url"/>
              </router-link>
              <div class="title-and-username">
                <p class="title">{{ portfolio.name }}</p>
                <p class="sub-title" v-if="portfolio.rating_user.id">{{ receivedUser.username }}, <router-link :to="'/'+portfolio.rating_user.username">{{portfolio.rating_user.username}}</router-link> için yaptı.</p>
                <p v-else class="sub-title">{{portfolio.category_sub_name}}</p>
              </div>
            </div>


            <div style="">
              <div v-if="portfolio.items && portfolio.items.length > 1" class="portfolio-items-wrapper" :style="portfolio.items.length > 5 ? '' : 'display:flex; flex-direction:row-reverse'">
                <carousel :perPage="5" :scrollPerPage="true" :navigationEnabled="true" navigationNextLabel="" navigationPrevLabel="" :paginationEnabled="false">
                  <slide v-for="(item, index) in portfolio.items" :key="index" :style="index % 5 !== 0 ? 'margin-right: 15px' : 'margin-right: 15px'">
                    <div @click="selectedIndex = index" class="portfolio-item" :class="selectedIndex === index ? 'active' : null">
                      <img @click="selectItem(item)" :id="'carusel-image-attachment' + index" class="att-img"
                           :src="item.image_url_small"/>
                    </div>
                  </slide>
                </carousel>
              </div>
            </div>
          </div>


          <div @click="$emit('close')" style="position: absolute; right: 50px; top: 53px; cursor: pointer">
            <img src="https://gcdn.bionluk.com/site/cicons/ic-delete.svg" onload="SVGInject(this)" class="close-icon"/>
          </div>
        </div>
        <div class="showcase-separator"/>
        <div class="showcase-body">
          <div class="mediaArea">
            <div v-if="videoLoader && showIframe" class="loader-div">
              <img :src="selectedItem.cover.image_url"/>
              <div>
                <loader style="height: 30px;width: 30px;"/>
              </div>
            </div>
            <div v-if="showIframe" class="embeded-video">
              <iframe @load="deactivateIframeLoader()"
                      v-show="!videoLoader"
                      allowfullscreen="true"
                      webkitallowfullscreen="true"
                      mozallowfullscreen="true"
                      frameborder="0"
                      scrolling="no"
                      :src="'https://api.bionluk.com/general/video/' + portfolio.uuid +'/portfolioattachment_'+selectedItem.upload_id+'x'+selectedItem.cover.upload_id+'/'"
              />
            </div>
            <div v-else style="display: flex; justify-content: center; align-items: flex-start;">
              <img :style="{'cursor': showIframe ? 'pointer' : 'default' }" :src="selectedItem.image_url_original"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  export default {
    name: "ShowcaseModal",
    props: {
      selectedCarousel:{
        type: Number,
        default: () => (0)
      },
      receivedUser: {
        type: Object,
        default: () => ({
          avatar_url: null,
          username: null
        })
      },
      portfolio: {
        type: Object,
        default: () => ({
          rating_user: {
            id: null,
            username
          },
          category_sub_name: null,
          items: []
        })
      },
    },
    data() {
      return {
        selectedIndex: 0,
        videoLoader: false
      }
    },
    methods: {
      selectItem(index) {
        if (index !== this.selectedIndex) {
          this.selectedIndex = index
          this.videoLoader = true
        }
      },
      deactivateIframeLoader() {
        this.videoLoader = false;
      }
    },
    computed: {
      selectedItem() {
        return this.portfolio.items[this.selectedIndex] || {}
      },
      showIframe() {
        return (this.selectedItem.video_url && this.selectedItem.file_type === 'video') || this.selectedItem.file_type === 'audio'
      }
    },
    created() {
      this.Helper.trackEvents.pageView(this.Helper.projectShowcaseAttachmentPageProps(this.portfolio),'projectshowcaseattachment','v1');
      document.body.style.overflow = 'hidden';
      this.selectedIndex = this.selectedCarousel;
    },
    beforeDestroy() {
      document.body.style.overflow = 'auto'
    }
  }
</script>

<style lang="scss" scoped>

  .att-img{
    border-radius:4px;
   width: 100%;
    height: 100%;
  }
  .modal-showcase-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    transition: opacity .3s ease;
  }

  .modal-showcase-container {
    width: 100%;
    height: 100%;
    min-width: 1195px;
    background-color: #2d3640;
    transition: all .3s ease;
    .showcase-header {
      height: 130px;
      align-items: center;
      position: relative;
      display: flex;
      .header-wrapper{
        display: flex;
        margin-left: 40px;
        margin-right: 124px;
        align-items: center;
        height: 60px;

        .avatar {
          width: 58px;
          height: 58px;
          border-radius: 50%;
          border:2px solid #bfc8d2
        }
        .title-and-username {
          height: 60px;
          margin-left: 22px;
          margin-top: 12px;
          .title {
            font-size: 18px;
            font-weight: 600;
            color: #ffffff;
            letter-spacing: -0.56px;

          }

          .sub-title {
            margin-top: 6px;
            font-size: 16px;
            font-weight: 300;
            color: #ffffff;
            a{
              font-size: 16px;
              font-weight: 600;
              color: #fd4056;
              &:hover{
                text-decoration: underline;
              }
            }
          }

        }


      }
    }
    .portfolio-items-wrapper{
      position: absolute;
      right: 140px;
      top:30px;
      width: 665px;

      .portfolio-item {
        width: 115px;
        height: 64px;
        cursor: pointer;
        border-radius: 7px;
        background-color: #ffffff;
        border: solid 3px #fff;
        &.active {
          border: solid 3px #00a575;
        }
      }
    }

    .showcase-separator {
      width: 100%;
      min-width: 1195px;
      height: 1px;
      opacity: 0.3;
      background-color: #bfc8d2;
    }
    .showcase-body {
      max-width: 1195px;
      margin: 50px auto 80px;
      img{
        margin: auto;
        box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.5);
        max-width: 1195px;
      }
    }
  }

  .modal-showcase-enter {
    opacity: 0;
  }

  .modal-showcase-leave-active {
    opacity: 0;
  }

  .modal-showcase-enter .modal-showcase-container,
  .modal-showcase-leave-active .modal-showcase-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }

  .close-icon /deep/{
    width: 24px;
    height: 24px;
    path {
      fill: #ffffff !important;
    }
  }

  .embeded-video {
    position: relative;
    width: 1195px;
    height: 672px;
    IFRAME {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }


</style>
